/* eslint-disable max-len */
/* eslint-disable no-else-return */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-useless-return */
/* eslint-disable consistent-return */
import React, { memo } from 'react';
import parse from 'html-react-parser';
import dynamic from 'next/dynamic';

const FlashSaleTimer = dynamic(() => import('@core_modules/commons/FlashSaleTimer'));
const WidgetPwaLink = dynamic(() => import('@core_modules/cms/components/cms-renderer/widget-link-pwa'));
const WidgetListProduct = dynamic(() => import('@core_modules/cms/components/cms-renderer/widget-list-product'));
const WidgetListBrand = dynamic(() => import('@core_modules/cms/components/cms-renderer/widget-list-brand'));
const WidgetInstagram = dynamic(() => import('@core_modules/cms/components/cms-renderer/widget-instagram'));
const WidgetSlider = dynamic(() => import('@core_modules/cms/components/cms-renderer/widget-slider'));
const WidgetMultipleSliders = dynamic(() => import('@core_modules/cms/components/cms-renderer/widget-multiple-slider'));
const BannerSlider = dynamic(() => import('@core_modules/cms/components/cms-renderer/widget-banner-slider'));
const WidgetFireworkEmbedded = dynamic(() => import('@core_modules/cms/components/cms-renderer/widget-firework-embedded'));
const WidgetFireworkCustom = dynamic(() => import('@core_modules/cms/components/cms-renderer/widget-firework-custom'));
const WidgetView = dynamic(() => import('@core_modules/cms/components/cms-renderer/view'));
const WidgetThumborImage = dynamic(() => import('@core_modules/cms/components/cms-renderer/widget-thumbor-image'));

const TYPE_PAGE = 'page';

const TYPE_PWA_SLIDER = 'pwa-slider';
const TYPE_PWA_MULTIPLE_SLIDER = 'pwa-multi-sliders';
const TYPE_PWA_FEATURED = 'pwa-featured-brands';
const TYPE_PWA_INSTAGRAM = 'pwa-instagram';
const TYPE_PWA_PAGELINK = 'pwa-cms-page-link';
const TYPE_PWA_PRODUCT = 'pwa-catalog-products-list';
const TYPE_PWA_FIREWORK_CUSTOM = 'pwa-firework-custom';

const DOM_NAME = 'pwa';

const CmsRenderer = (props) => {
    const { type, content, storeConfig } = props;

    /**
     * component conversion
     * NOTES*: validateDOMNesting(...): <div> cannot appear as a descendant of <p>
     * parent cms page || block must start with <div>
     * @returns {COMPONENT}
     */
    const options = {
        replace: (domNode) => {
            if (domNode.name === DOM_NAME && domNode.attribs && domNode.parent) {
                const propsWidget = domNode.attribs;
                const parentClass = domNode.parent.attribs.class;
                switch (domNode.attribs.type) {
                case TYPE_PWA_SLIDER:
                    return <WidgetSlider {...propsWidget} storeConfig={storeConfig} />;
                case TYPE_PWA_FEATURED:
                    return <WidgetListBrand {...propsWidget} />;
                case TYPE_PWA_INSTAGRAM:
                    return <WidgetInstagram {...propsWidget} />;
                case TYPE_PWA_PAGELINK:
                    return <WidgetPwaLink {...propsWidget} />;
                case TYPE_PWA_PRODUCT:
                    return <WidgetListProduct storeConfig={storeConfig} {...propsWidget} parentClass={parentClass} />;
                case TYPE_PWA_FIREWORK_CUSTOM:
                    return <WidgetFireworkCustom {...propsWidget} storeConfig={storeConfig} />;
                case TYPE_PWA_MULTIPLE_SLIDER:
                    return <WidgetMultipleSliders {...propsWidget} storeConfig={storeConfig} />;
                default:
                    return <div>Unable to render the content!</div>;
                }
            } else if (domNode?.attribs?.class === 'pwa-firework-embedded' && domNode?.children[0]?.data) {
                const fireworkContent = domNode.children;
                return <WidgetFireworkEmbedded fireworkContent={fireworkContent} domNode={domNode} />;
            } else if (domNode?.attribs?.class === 'pwa-flash-sale-end' && domNode?.children[0]?.data && domNode?.attribs?.id) {
                const flashSaleEnd = domNode.children[0].data;
                const parentId = domNode.attribs.id;
                return <FlashSaleTimer flashSaleEnd={flashSaleEnd} parentId={parentId} />;
            } else if (domNode?.attribs?.id === 'pwa-mini-banner' && domNode?.children[0]?.data && domNode?.attribs?.id) {
                const miniBanner = domNode.children;
                return <BannerSlider data={miniBanner} domNode={domNode} />;
            } else if (domNode.name === 'img') {
                return (
                    <WidgetThumborImage {...domNode.attribs} />
                );
            } else if (!domNode.attribs) {
                return;
            }
        },
    };

    const WidgetComponent = () => {
        if (type === TYPE_PAGE) {
            return parse(content, options);
        }
    };
    /**
     * other props
     */
    const propsOther = {
        WidgetComponent,
    };

    return <WidgetView {...props} {...propsOther} />;
};

const notRenderIf = (prevProps, nextProps) => prevProps.content === nextProps.content;

export default memo(CmsRenderer, notRenderIf);
